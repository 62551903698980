





























import { Component, Vue, PropSync } from "vue-property-decorator";
import { UtilsPublicResources } from "@/utils/utils-public-resources";
@Component({
  components: {}
})
export default class CompFin extends Vue {
  @PropSync("desc", { default: "Ha completado correctamente la consulta" })
  descripcion!: string;
  @PropSync("tit", { default: "Finalizar" })
  titulobtn!: string;
  public getimagen(imagen: string) {
    return UtilsPublicResources.getImageFromImages(imagen);
  }
}
